import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	modalTitle: {
		id: 'bulk-connect-dialog-title',
		defaultMessage: 'Connect your apps',
		description: 'Title for the bulk connect dialog',
	},
	modalSubheading: {
		id: 'bulk-connect-dialog-subheading',
		defaultMessage: 'Find your work more efficiently by individually connecting the apps you use',
		description: 'Subheading for the bulk connect dialog',
	},
	cancel: {
		id: 'bulk-connect-cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button for the bulk connect dialog',
	},
	done: {
		id: 'bulk-connect-done',
		defaultMessage: 'Done',
		description: 'Done button for the bulk connect dialog',
	},
});
