import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@atlaskit/modal-dialog';
import { Box, Flex, Grid, Stack, xcss } from '@atlaskit/primitives';

import { type ProductKeys, ProductKeys3P } from '../../constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';
import { ProductTile } from '../product-tile';

import { messages } from './messages';
import { BulkConnectTextfield } from './search-input';

const titleStyles = xcss({
	color: 'color.text',
	font: 'font.heading',
	fontFamily: 'font.family.brand.heading',
	fontWeight: 'font.weight.medium',
	paddingBottom: 'space.200',
	width: '100%',
});

const subheadingStyles = xcss({
	color: 'color.text.subtle',
	font: 'font.body',
	fontFamily: 'font.family.brand.body',
	fontWeight: 'font.weight.regular',
	paddingBottom: 'space.200',
	width: '100%',
});

const defaultProductOrder = [
	ProductKeys3P.GoogleDrive,
	ProductKeys3P.Slack,
	ProductKeys3P.Sharepoint,
	ProductKeys3P.Github,
	ProductKeys3P.Teams,
	ProductKeys3P.OneDrive,
	ProductKeys3P.Notion,
	ProductKeys3P.Asana,
	ProductKeys3P.Zendesk,
	ProductKeys3P.Miro,
	ProductKeys3P.Gitlab,
	ProductKeys3P.Monday,
	ProductKeys3P.Smartsheet,
	ProductKeys3P.ServiceNow,
	ProductKeys3P.Figma,
	ProductKeys3P.PowerBI,
	ProductKeys3P.AdobeXD,
	ProductKeys3P.Salesforce,
	ProductKeys3P.Canva,
	ProductKeys3P.Stripe,
	ProductKeys3P.Mural,
	ProductKeys3P.Amplitude,
	ProductKeys3P.Pipedrive,
	ProductKeys3P.Dovetail,
	ProductKeys3P.Webex,
	ProductKeys3P.PagerDuty,
	ProductKeys3P.Todoist,
	ProductKeys3P.LaunchDarkly,
	ProductKeys3P.Hubspot,
	ProductKeys3P.Sentry,
];

type BulkConnectDialogProps = {
	availableProducts: ProductKeys[];
	closeModal: () => void;
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	newlyConnectedProducts: ProductKeys[];
};

export const BulkConnectDialog = ({
	availableProducts,
	closeModal,
	thirdPartyConfigs,
	newlyConnectedProducts,
}: BulkConnectDialogProps) => {
	let allConfigs = thirdPartyConfigs;

	let productTilesToBeDisplayed: JSX.Element[] = [];
	//TODO: Implement the logic to display the products in order using newlyConnectedProducts ProductsKeyList
	let productsToBeDisplayed = defaultProductOrder.filter((product) =>
		availableProducts.includes(product),
	);

	for (const product of productsToBeDisplayed) {
		const config = allConfigs[product as ProductKeys3P];
		if (config) {
			productTilesToBeDisplayed.push(
				<ProductTile
					id={config.id}
					icon={config.searchResultIcon ? config.searchResultIcon : config.icon}
					productName={config.displayName}
					url={config.oAuthOutboundUrl ? config.oAuthOutboundUrl : ''}
					connectStatus={config.userNeedsOAuth ? 'default' : 'connected'}
				/>,
			);
		}
	}

	return (
		<Modal onClose={closeModal} width={'x-large'} height="666px">
			<ModalHeader>
				<Stack grow="fill">
					<Box xcss={titleStyles}>
						<FormattedMessage {...messages.modalTitle} />
					</Box>
					<Box xcss={subheadingStyles}>
						<FormattedMessage {...messages.modalSubheading} />
					</Box>
					<BulkConnectTextfield />
				</Stack>
			</ModalHeader>
			<ModalBody>
				<Grid gap="space.150" alignItems="center" templateColumns="1fr 1fr 1fr 1fr 1fr">
					{productTilesToBeDisplayed.map((productTile) => productTile)}
				</Grid>
			</ModalBody>
			<ModalFooter>
				<Flex gap="space.100">
					<Button onClick={closeModal} appearance="subtle">
						<FormattedMessage {...messages.cancel} />
					</Button>
					<Button onClick={closeModal} appearance="primary">
						<FormattedMessage {...messages.done} />
					</Button>
				</Flex>
			</ModalFooter>
		</Modal>
	);
};
