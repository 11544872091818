import React from 'react';

import { useIntl } from 'react-intl-next';

import SearchIcon from '@atlaskit/icon/core/search';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';

const inputStyles = xcss({
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.100',
	color: 'color.text.subtlest',
});

const iconStyles = xcss({
	paddingLeft: 'space.025',
	paddingRight: 'space.025',
});

const searchBarStyles = xcss({
	width: '100%',
	borderRadius: '5px',
	border: '1px solid',
	borderColor: 'color.border.input',
	background: token('color.background.input'),
});

export const BulkConnectTextfield = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={searchBarStyles}>
			<Inline space="space.025" xcss={inputStyles}>
				<Box xcss={iconStyles}>
					<SearchIcon label={formatMessage(messages.searchIconLabel)} />
				</Box>
				<Textfield placeholder={formatMessage(messages.searchPlaceholder)} appearance="none" />
			</Inline>
		</Box>
	);
};
