import { DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { LoomIcon } from './icon';
import { LoomSearchResultIcon } from './search-result-icon';

export const loomConfig: ThirdPartyConfig = {
	displayName: 'Loom',
	id: 'loom',
	icon: get3PLogo(LoomIcon, 'Loom', 'medium'),
	searchResultIcon: get3PLogo(LoomSearchResultIcon, 'Loom', 'large'),
	integrationAri: 'ari:cloud:platform::integration/loom',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Video,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated'],
	requiresOauth: false,
	isEnabled: () => true,
};
