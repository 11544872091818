import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	searchIconLabel: {
		id: 'search-icon-label',
		defaultMessage: 'Search',
		description: 'Label text for search icon',
	},
	searchPlaceholder: {
		id: 'search-icon-placeholder',
		defaultMessage: 'Search',
		description: 'Placeholder text for search input',
	},
});
