import { fg } from '@atlaskit/platform-feature-flags';
import {
	confluenceCQLQuery,
	type EntityATI,
	fetchJiraFieldsQuery,
	generateSearchPageCacheKey,
	type ResultsSort,
	searchPageQuery,
	searchPageQueryCache,
	SORT_FIELD_KEY,
} from '@atlassian/search-client';
import type { SearchExperimentLayer } from '@atlassian/search-experiment';

import { type PrimaryProductKey } from '../../../common/constants/products';
import { type SelectedFiltersType } from '../../../common/constants/types';
import { getEmptySelectedFilters } from '../../../common/utils/filters';

export class ResultsFetchError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ResultsFetchError';
	}
}

export class EmptyEntitiesError extends ResultsFetchError {
	constructor() {
		super('No entities provided to fetch results');
		this.name = 'EmptyEntitiesError';
	}
}

export type FetchResultsProps = {
	cql?: string;
	query: string;
	originalQuery?: string;
	entities: EntityATI[];
	selectedFilters: SelectedFiltersType | null;
	cloudId: string;
	aggAbsoluteUrl?: string | undefined;
	searchSessionId: string;
	sort: ResultsSort;
	sourceProduct?: PrimaryProductKey;
	experimentId?: string;
	shadowExperimentId?: string;
	experimentLayers?: SearchExperimentLayer[];
	after?: string;
	limit: number;
	enableHighlighting?: boolean;
};

export const fetchResults = async ({
	cql,
	query,
	originalQuery,
	entities,
	selectedFilters,
	cloudId,
	aggAbsoluteUrl,
	searchSessionId,
	sort,
	sourceProduct,
	experimentId,
	shadowExperimentId,
	experimentLayers,
	after,
	limit,
	enableHighlighting,
}: FetchResultsProps) => {
	if (cql) {
		const response = confluenceCQLQuery(
			{
				cql,
				first: limit,
				after,
			},
			cloudId,
		);

		return response;
	}

	const { commonFilters, confluenceFilters, jiraFilters, mercuryFilters, thirdPartyFilters } =
		selectedFilters || getEmptySelectedFilters();

	const sortVariables = sort.field === SORT_FIELD_KEY.RELEVANCE ? [] : [sort];

	// If the original query param is present, we want to disable query replacement
	const disableQueryReplacement = !!originalQuery;

	const variables = {
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit,
		commonFilters,
		confluenceFilters,
		jiraFilters,
		mercuryFilters,
		thirdPartyFilters,
		entities,
		searchSessionId,
		sort: sortVariables,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers: experimentLayers?.map(({ name, layerId, shadowId, definitions }) => ({
			name,
			layerId,
			shadowId,
			definitions,
		})),
		after: fg('rovo-search-results-pagination-fps') ? after : undefined,
		disableQueryReplacement,
		enableHighlighting,
	};

	if (entities.length === 0) {
		throw new EmptyEntitiesError();
	}

	const response = searchPageQuery({
		variables,
		aggAbsoluteUrl,
		originalQuery,
	});

	// TODO: QS-6145 maybe here is a good place to merge results into cache store as HTTP response parts come in ?
	// const deferredResponse = deferredSearchPageQuery({ variables, aggAbsoluteUrl, originalQuery });

	// If the original query param is not present, we want to cache the response with the replacement query
	if (!originalQuery) {
		const value = await response;
		const queryInfo = value.data?.search.results.queryInfo;
		if (
			queryInfo &&
			queryInfo.replacementQuery &&
			queryInfo.originalQuery &&
			queryInfo.replacementQuery !== queryInfo.originalQuery
		) {
			searchPageQueryCache.set(
				generateSearchPageCacheKey(
					{ ...variables, query: queryInfo.replacementQuery },
					queryInfo.originalQuery,
				),
				response,
			);
		}
	}

	return response;
};

export const fetchJiraFields = async ({
	issueKeys,
	cloudId,
	aggAbsoluteUrl,
}: {
	issueKeys: string[];
	cloudId: string;
	aggAbsoluteUrl?: string | undefined;
}) => {
	const variables = {
		issueKeys,
		cloudId,
	};

	const response = fetchJiraFieldsQuery({
		variables,
		aggAbsoluteUrl,
	});

	return response;
};
