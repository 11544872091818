import cloneDeep from 'lodash/cloneDeep';

import { fg } from '@atlaskit/platform-feature-flags';
import { type EntityATI } from '@atlassian/search-client';

import { ThirdPartyConfigs } from '../../constants/3p-product-configs';
import {
	CONFLUENCE_ANSWER_ENTITY_ATI,
	CONFLUENCE_CALENDAR_ENTITY_ATI,
	CONFLUENCE_GLIFFY_ENTITY_ATI,
	CONFLUENCE_QUESTION_ENTITY_ATI,
	CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_PROJECT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
	DEFAULT_MERCURY_ENTITY_ATI,
	FEDERATED_GOOGLE_EMAIL_ENTITY_ATI,
	FEDERATED_MICROSOFT_EMAIL_ENTITY_ATI,
	JIRA_ISSUE_ENTITY_ATI,
	JIRA_PROJECT_ENTITY_ATI,
} from '../../constants/entities';
import type { TypeFilterMapping, UniversalTypeFilterMapping } from '../../constants/filters/types';
import {
	allTypeFilterValueKeys,
	NounSubtypeKey,
	TypeFilterValueKey,
} from '../../constants/filters/universal-type';
import { ProductKeys, type ProductKeys1P } from '../../constants/products';

// Simple first-call-fills cache
let cachedOldTypeMapping: UniversalTypeFilterMapping;
const getOldTypeMapping: () => UniversalTypeFilterMapping = () => {
	if (cachedOldTypeMapping) {
		return cachedOldTypeMapping;
	}

	const mapping: UniversalTypeFilterMapping = {
		[TypeFilterValueKey.Document]: {
			products: [
				ProductKeys.Confluence,
				ProductKeys.GoogleDrive,
				ProductKeys.Sharepoint,
				ProductKeys.OneDrive,
				ProductKeys.Dropbox,
				ProductKeys.Airtable,
				ProductKeys.Smartsheet,
				ProductKeys.Notion,
				ProductKeys.Docusign,
				ProductKeys.Monday,
				ProductKeys.Box,
				ProductKeys.AdobeSign,
				ProductKeys.Dovetail,
				ProductKeys.Gitbook,
				ProductKeys.AWSS3,
			],
			entities: {
				confluence: [
					'ati:cloud:confluence:blogpost',
					'ati:cloud:confluence:page',
					'ati:cloud:confluence:attachment',
				],
				drive: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				onedrive: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				sharepoint: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				dropbox: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				airtable: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				smartsheet: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				notion: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				docusign: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				monday: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				box: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				'adobe-sign': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				dovetail: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				gitbook: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				'aws-s3': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Document],
		},
		[TypeFilterValueKey.Spreadsheet]: {
			products: [
				ProductKeys.GoogleDrive,
				ProductKeys.Sharepoint,
				ProductKeys.OneDrive,
				ProductKeys.Dropbox,
				ProductKeys.Box,
			],
			entities: {
				drive: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				onedrive: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				sharepoint: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				dropbox: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				box: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Spreadsheet],
		},
		[TypeFilterValueKey.Presentation]: {
			products: [
				ProductKeys.GoogleDrive,
				ProductKeys.Sharepoint,
				ProductKeys.OneDrive,
				ProductKeys.Dropbox,
				ProductKeys.Box,
			],
			entities: {
				drive: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				onedrive: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				sharepoint: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				dropbox: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				box: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Presentation],
		},
		[TypeFilterValueKey.Repository]: {
			products: [
				ProductKeys.Bitbucket,
				ProductKeys.Github,
				ProductKeys.AzureDevops,
				ProductKeys.Gitlab,
			],
			entities: {
				github: DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
				bitbucket: DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
				'azure-devops': DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
				gitlab: [
					...DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
			},
			subCategories: [NounSubtypeKey.Repository],
		},
		[TypeFilterValueKey.PullRequest]: {
			products: [ProductKeys.Bitbucket, ProductKeys.Github],
			entities: {
				github: ['ati:cloud:graph:pull-request'],
				bitbucket: ['ati:cloud:graph:pull-request'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Branch]: {
			products: [ProductKeys.Bitbucket, ProductKeys.Github],
			entities: {
				github: ['ati:cloud:graph:branch'],
				bitbucket: ['ati:cloud:graph:branch'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Commit]: {
			products: [ProductKeys.Bitbucket, ProductKeys.Github],
			entities: {
				github: ['ati:cloud:graph:commit'],
				bitbucket: ['ati:cloud:graph:commit'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.WebPage]: {
			products: [ProductKeys.AtlassianWebCrawler],
			entities: {
				'atlassian-web-crawler': ['ati:cloud:graph:document'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Conversation]: {
			products: [ProductKeys.Slack, ProductKeys.Teams],
			entities: {
				slack: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
				teams: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Message]: {
			products: [ProductKeys.Slack, ProductKeys.Teams, ProductKeys.Gmail, ProductKeys.OutlookMail],
			entities: {
				slack: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
				teams: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
				gmail: [FEDERATED_GOOGLE_EMAIL_ENTITY_ATI],
				'outlook-mail': [FEDERATED_MICROSOFT_EMAIL_ENTITY_ATI],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Video]: {
			products: [ProductKeys.Loom, ProductKeys.Webex],
			entities: {
				loom: DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
				webex: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Design]: {
			products: [
				ProductKeys.Figma,
				ProductKeys.Miro,
				ProductKeys.Lucid,
				ProductKeys.Canva,
				ProductKeys.Zeplin,
				ProductKeys.AdobeXD,
				ProductKeys.Mural,
			],
			entities: {
				figma: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
				miro: [
					...DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				lucid: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
				canva: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				zeplin: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				adobexd: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				mural: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Design],
		},
		[TypeFilterValueKey.Page]: {
			products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC],
			entities: {
				confluence: ['ati:cloud:confluence:page'],
				'confluence-dc': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Page],
		},
		[TypeFilterValueKey.Blogpost]: {
			products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC],
			entities: {
				confluence: ['ati:cloud:confluence:blogpost'],
				'confluence-dc': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Blogpost],
		},
		[TypeFilterValueKey.Attachment]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: ['ati:cloud:confluence:attachment'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Space]: {
			products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC],
			entities: {
				confluence: ['ati:cloud:confluence:space'],
				'confluence-dc': DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Comment]: {
			products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC, ProductKeys.JiraDC],
			entities: {
				confluence: ['ati:cloud:confluence:comment'],
				'confluence-dc': DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
				'jira-dc': DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Whiteboard]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: ['ati:cloud:confluence:whiteboard'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Database]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: ['ati:cloud:confluence:database'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Embed]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: ['ati:cloud:confluence:embed'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Folder]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: ['ati:cloud:confluence:folder'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Calendar]: {
			products: [ProductKeys.Confluence, ProductKeys.GoogleCalendar, ProductKeys.OutlookCalendar],
			entities: {
				confluence: [CONFLUENCE_CALENDAR_ENTITY_ATI],
				'google-calendar': DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
				'outlook-calendar': DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.SpaceCalendar]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: [CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Question]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: [CONFLUENCE_QUESTION_ENTITY_ATI],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Answer]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: [CONFLUENCE_ANSWER_ENTITY_ATI],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Gliffy]: {
			products: [ProductKeys.Confluence],
			entities: {
				confluence: [CONFLUENCE_GLIFFY_ENTITY_ATI],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Issue]: {
			products: [
				ProductKeys.Jira,
				ProductKeys.JiraProductDiscovery,
				ProductKeys.JiraServiceManagement,
				ProductKeys.JiraDC,
			],
			entities: {
				jira: [JIRA_ISSUE_ENTITY_ATI],
				[ProductKeys.JiraProductDiscovery]: [JIRA_ISSUE_ENTITY_ATI],
				[ProductKeys.JiraServiceManagement]: [JIRA_ISSUE_ENTITY_ATI],
				'jira-dc': [
					...DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_PROJECT_ENTITY_ATI,
				],
			},
			subCategories: [NounSubtypeKey.Issue],
		},
		[TypeFilterValueKey.Component]: {
			products: [ProductKeys.Compass],
			entities: {
				compass: ['ati:cloud:compass:component'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Card]: {
			products: [ProductKeys.Trello],
			entities: {
				trello: ['ati:cloud:trello:card'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Project]: {
			products: [
				ProductKeys.Jira,
				ProductKeys.JiraProductDiscovery,
				ProductKeys.JiraServiceManagement,
				ProductKeys.Atlas,
				ProductKeys.JiraDC,
			],
			entities: {
				jira: [JIRA_PROJECT_ENTITY_ATI],
				[ProductKeys.JiraProductDiscovery]: [JIRA_PROJECT_ENTITY_ATI],
				[ProductKeys.JiraServiceManagement]: [JIRA_PROJECT_ENTITY_ATI],
				townsquare: ['ati:cloud:townsquare:project'],
				'jira-dc': DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Project],
		},
		[TypeFilterValueKey.Goal]: {
			products: [ProductKeys.Atlas],
			entities: {
				townsquare: ['ati:cloud:townsquare:goal'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Tag]: {
			products: [ProductKeys.Atlas],
			entities: {
				townsquare: ['ati:cloud:townsquare:tag'],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.WorkItem]: {
			products: [
				ProductKeys.Asana,
				ProductKeys.Zendesk,
				ProductKeys.ServiceNow,
				ProductKeys.Freshservice,
				ProductKeys.ClickUp,
				ProductKeys.Aha,
				ProductKeys.Todoist,
				ProductKeys.PagerDuty,
				ProductKeys.LaunchDarkly,
				ProductKeys.Sentry,
			],
			entities: {
				asana: [
					...DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				zendesk: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
				servicenow: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
				freshservice: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
				clickup: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				aha: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
				todoist: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				pagerduty: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				launchdarkly: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				sentry: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			},
			subCategories: [NounSubtypeKey.Task],
		},
		[TypeFilterValueKey.Incident]: {
			products: [ProductKeys.JiraServiceManagement],
			entities: {
				[ProductKeys.JiraServiceManagement]: [JIRA_ISSUE_ENTITY_ATI],
			},
			subCategories: [],
		},
		[TypeFilterValueKey.FocusArea]: {
			products: [ProductKeys.Focus],
			entities: {
				mercury: DEFAULT_MERCURY_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Dashboard]: {
			products: [ProductKeys.PowerBI, ProductKeys.Amplitude], // TODO: AI3-2643 Add DataDog, Databricks
			entities: {
				'power-bi': DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				amplitude: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Invoice]: {
			products: [ProductKeys.Stripe],
			entities: {
				stripe: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			},
			subCategories: [],
		},
		[TypeFilterValueKey.Deal]: {
			products: [ProductKeys.Pipedrive, ProductKeys.Hubspot, ProductKeys.Salesforce],
			entities: {
				pipedrive: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				hubspot: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				salesforce: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			},
			subCategories: [],
		},
	};

	cachedOldTypeMapping = mapping;
	return mapping;
};

export type TypeFilterProductMapping1P = Map<TypeFilterValueKey, EntityATI[]>;
/**
 * A mapping between 1P Products
 * - ProductKey1P e.g. 'confluence'
 *
 * UI values for the type filter
 * - TypeFilterKey e.g. 'Spreadsheet'
 *
 * and entities to request from Aggregator
 *
 * - ATIString[] e.g. ['ati:cloud:confluence:blogpost']
 */
const typeFilter1PMapping = new Map<ProductKeys1P, TypeFilterProductMapping1P>([
	[
		ProductKeys.Confluence,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[
				TypeFilterValueKey.Document,
				[
					'ati:cloud:confluence:blogpost',
					'ati:cloud:confluence:page',
					'ati:cloud:confluence:attachment',
				],
			],
			[TypeFilterValueKey.Page, ['ati:cloud:confluence:page']],
			[TypeFilterValueKey.Blogpost, ['ati:cloud:confluence:blogpost']],
			[TypeFilterValueKey.Attachment, ['ati:cloud:confluence:attachment']],
			[TypeFilterValueKey.Space, ['ati:cloud:confluence:space']],
			[TypeFilterValueKey.Comment, ['ati:cloud:confluence:comment']],
			[TypeFilterValueKey.Whiteboard, ['ati:cloud:confluence:whiteboard']],
			[TypeFilterValueKey.Database, ['ati:cloud:confluence:database']],
			[TypeFilterValueKey.Embed, ['ati:cloud:confluence:embed']],
			[TypeFilterValueKey.Folder, ['ati:cloud:confluence:folder']],
			[TypeFilterValueKey.Calendar, [CONFLUENCE_CALENDAR_ENTITY_ATI]],
			[TypeFilterValueKey.SpaceCalendar, [CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI]],
			[TypeFilterValueKey.Question, [CONFLUENCE_QUESTION_ENTITY_ATI]],
			[TypeFilterValueKey.Answer, [CONFLUENCE_ANSWER_ENTITY_ATI]],
			[TypeFilterValueKey.Gliffy, [CONFLUENCE_GLIFFY_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.Jira,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.JiraProductDiscovery,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.JiraServiceManagement,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
			[TypeFilterValueKey.Incident, [JIRA_ISSUE_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.Compass,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Component, ['ati:cloud:compass:component']],
		]),
	],
	[
		ProductKeys.Focus,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[
				TypeFilterValueKey.FocusArea,
				['ati:cloud:mercury:focus-area', 'ati:cloud:mercury:focus-area-status-update'],
			],
		]),
	],
	[
		ProductKeys.Trello,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Card, ['ati:cloud:trello:card']],
		]),
	],
	[
		ProductKeys.Atlas,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Project, ['ati:cloud:townsquare:project']],
			[TypeFilterValueKey.Goal, ['ati:cloud:townsquare:goal']],
			[TypeFilterValueKey.Tag, ['ati:cloud:townsquare:tag']],
		]),
	],
	[
		ProductKeys.Bitbucket,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Commit, ['ati:cloud:graph:commit']],
			[TypeFilterValueKey.PullRequest, ['ati:cloud:graph:pull-request']],
			[TypeFilterValueKey.Repository, ['ati:cloud:graph:repository']],
			[TypeFilterValueKey.Branch, ['ati:cloud:graph:branch']],
		]),
	],
]);

// Simple first-call-fills cache
let cachedNewTypeMapping: UniversalTypeFilterMapping;
const getNewTypeMapping: () => UniversalTypeFilterMapping = () => {
	if (cachedNewTypeMapping) {
		return cachedNewTypeMapping;
	}

	const mappings: UniversalTypeFilterMapping = Object.fromEntries(
		allTypeFilterValueKeys.map((TypeFilterValueKey) => {
			const baseMapping: TypeFilterMapping = {
				entities: {},
				products: [],
				subCategories: [],
			};
			return [TypeFilterValueKey as TypeFilterValueKey, baseMapping];
		}),
	) as UniversalTypeFilterMapping;
	// Add all the 1P products
	typeFilter1PMapping.forEach((_, productKey1P) => {
		typeFilter1PMapping.get(productKey1P)?.forEach((_, typeFilterValueKey) => {
			mappings[typeFilterValueKey].products.push(productKey1P);
			mappings[typeFilterValueKey].entities[productKey1P] = typeFilter1PMapping
				.get(productKey1P)
				?.get(typeFilterValueKey);
		});
	});
	// Add all the 3P products
	ThirdPartyConfigs.forEach((thirdPartyConfig, productKey3P) => {
		const typeFilterConfigs = thirdPartyConfig?.typeFilterConfig;

		if (!typeFilterConfigs) {
			return;
		}

		typeFilterConfigs.forEach((typeFilterValue3PEntities, typeFilterValueKey) => {
			mappings[typeFilterValueKey].products.push(productKey3P);

			mappings[typeFilterValueKey].entities[productKey3P] = typeFilterValue3PEntities.entities3P;

			mappings[typeFilterValueKey].subCategories = [
				...mappings[typeFilterValueKey].subCategories,
				...typeFilterValue3PEntities.subTypes3P,
			];
			mappings[typeFilterValueKey].subCategories = [
				...new Set(mappings[typeFilterValueKey].subCategories),
			]; // dedupe
		});
	});

	cachedNewTypeMapping = mappings;
	return mappings;
};

// Simple first-call-fills cache
let cachedNewTypeMappingWithSmartlinkSubtypes: UniversalTypeFilterMapping;
// Cleanup during fg('smartlink-subtypes')
const getNewTypeMappingWithSmartlinkSubtypes: () => UniversalTypeFilterMapping = () => {
	if (cachedNewTypeMappingWithSmartlinkSubtypes) {
		return cachedNewTypeMappingWithSmartlinkSubtypes;
	}

	// we need to deep clone, otherwise the below modifies our constant
	// This is short-lived, smartlink-subtypes is rolled out and during cleanup this can be removed
	// cloneDeep shouldn't have performance concerns, this is a small fixed size object
	let newTypeMappings = cloneDeep(getNewTypeMapping());

	newTypeMappings[TypeFilterValueKey.Video].subCategories = [NounSubtypeKey.Video];
	newTypeMappings[TypeFilterValueKey.Design].subCategories = [NounSubtypeKey.Design];
	newTypeMappings[TypeFilterValueKey.Dashboard].subCategories = [NounSubtypeKey.Dashboard];
	newTypeMappings[TypeFilterValueKey.Invoice].subCategories = [NounSubtypeKey.Invoice];
	newTypeMappings[TypeFilterValueKey.Deal].subCategories = [NounSubtypeKey.Deal];
	newTypeMappings[TypeFilterValueKey.WorkItem].subCategories = [NounSubtypeKey.Task];

	cachedNewTypeMappingWithSmartlinkSubtypes = newTypeMappings;
	return newTypeMappings;
};

/**
 * @returns an array of disabled ATIs in the 'All' types filter state, if there are any
 */
export const featureGateNotRequestedATIsInAllTypesFilter = (
	dropAttachmentsComments: boolean,
): EntityATI[] => {
	return (
		[
			[dropAttachmentsComments, 'ati:cloud:confluence:comment' satisfies EntityATI],
			[dropAttachmentsComments, 'ati:cloud:confluence:attachment' satisfies EntityATI],
		] as const
	).flatMap(([dropATI, entityATI]) => (dropATI ? entityATI : []));
};

// Simple first-call-fills cache
let cachedGetOldTypeMappingWithSmartlinkSubtypes: UniversalTypeFilterMapping;
const getOldTypeMappingWithSmartlinkSubtypes: () => UniversalTypeFilterMapping = () => {
	if (cachedGetOldTypeMappingWithSmartlinkSubtypes) {
		return cachedGetOldTypeMappingWithSmartlinkSubtypes;
	}
	// we need to deep clone, otherwise the below modifies our constant
	// This is short-lived, smartlink-subtypes is rolled out and during cleanup this can be removed
	// cloneDeep shouldn't have performance concerns, this is a small fixed size object
	const typeMapping = cloneDeep(getOldTypeMapping());

	typeMapping[TypeFilterValueKey.Video].subCategories = [NounSubtypeKey.Video];
	typeMapping[TypeFilterValueKey.Design].subCategories = [NounSubtypeKey.Design];
	typeMapping[TypeFilterValueKey.Dashboard].subCategories = [NounSubtypeKey.Dashboard];
	typeMapping[TypeFilterValueKey.Invoice].subCategories = [NounSubtypeKey.Invoice];
	typeMapping[TypeFilterValueKey.Deal].subCategories = [NounSubtypeKey.Deal];
	typeMapping[TypeFilterValueKey.WorkItem].subCategories = [NounSubtypeKey.Task];

	cachedGetOldTypeMappingWithSmartlinkSubtypes = typeMapping;
	return typeMapping;
};

// Remove the "export" here when cleaning up rovo-full-page-search-3p-static-config
// Or just move this into getTypeMapping() entirely
// This export is to allow invariant testing for the FG path
export const getOldTypeMappingImpl: () => UniversalTypeFilterMapping = () => {
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (fg('smartlink-subtypes')) {
		return getOldTypeMappingWithSmartlinkSubtypes();
	} else {
		return getOldTypeMapping();
	}
};

export const getTypeMapping: () => UniversalTypeFilterMapping = () => {
	if (fg('rovo-full-page-search-3p-static-config')) {
		// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
		if (fg('smartlink-subtypes')) {
			return getNewTypeMappingWithSmartlinkSubtypes();
		} else {
			return getNewTypeMapping();
		}
	} else {
		return getOldTypeMappingImpl();
	}
};
