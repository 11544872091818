import React, { type ReactNode, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import CheckMarkIcon from '@atlaskit/icon/core/check-mark';
import ErrorIcon from '@atlaskit/icon/core/error';
import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import Lozenge from '@atlaskit/lozenge';
import { Anchor, Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { RovoLogo } from '../../constants/icons';

import { messages } from './messages';

const tileStyles = xcss({
	width: '174px',
	height: '134px',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'color.border',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const contentStyles = xcss({
	boxSizing: 'border-box',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
});

const iconStyles = xcss({
	width: '32px',
	height: '32px',
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'color.border',
});

const productTitleStyles = xcss({
	fontWeight: 'font.weight.medium',
	fontStyle: 'normal',
	color: 'color.text.subtle',
	fontFamily: 'font.family.brand.heading',
});

const connectLinkStyles = xcss({
	color: 'color.link',
	font: 'font.body',
	fontStyle: 'normal',
	textDecoration: 'none',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.150',
});

const connectedLinkStyles = xcss({
	color: 'color.text.accent.green',
	font: 'font.body',
	fontStyle: 'normal',
	textDecoration: 'none',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.150',
});

const errorLinkStyles = xcss({
	color: 'color.link',
	font: 'font.body',
	fontStyle: 'normal',
	textDecoration: 'none',
});

const errorMessageStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body',
	fontStyle: 'normal',
	paddingLeft: 'space.025',
	paddingRight: 'space.025',
});

const connectIconStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

type ConnectStatus = 'default' | 'connected' | 'error';

export type ProductTileProps = {
	id?: string;
	icon: RovoLogo;
	productName: ReactNode;
	url: string;
	connectStatus?: ConnectStatus;
	recentlyConnected?: boolean;
};

export const ProductTile = ({
	id,
	icon,
	productName,
	url,
	connectStatus,
	recentlyConnected,
}: ProductTileProps) => {
	const { formatMessage } = useIntl();
	let ProductIcon = icon;
	const [attemptedConnect, setAttemptedConnect] = useState(false);
	const [curConnectStatus, setCurConnectStatus] = useState(connectStatus);

	//TODO: add logic to trigger re-order of tiles & update store
	const onConnectClick = () => {
		setAttemptedConnect(true);
		updateTile();
	};

	const updateTile = () => {
		if (attemptedConnect && curConnectStatus === 'default') {
			//TODO: add logic to check if connection was successful, otherwise set status to 'error'
			connectStatus = 'error';
			setCurConnectStatus('error');
		}
	};

	let connectAction;
	switch (curConnectStatus) {
		case 'connected':
			connectAction = (
				<Inline space="space.050" alignBlock="center" xcss={connectedLinkStyles}>
					<CheckMarkIcon
						label={formatMessage(messages.checkIconLabel)}
						color={token('color.icon.accent.green')}
					/>
					<Box>
						<FormattedMessage {...messages.connected} />
					</Box>
				</Inline>
			);
			break;
		case 'error':
			connectAction = (
				<Inline alignBlock="center">
					<Inline space="space.050" alignBlock="center">
						<ErrorIcon
							label={formatMessage(messages.errorIconLabel)}
							color={token('color.icon.danger')}
						/>
						<Box xcss={errorMessageStyles}>
							<FormattedMessage {...messages.failed} />
						</Box>
					</Inline>
					<Anchor href={url} xcss={errorLinkStyles} onClick={onConnectClick}>
						<FormattedMessage {...messages.retry} />
					</Anchor>
				</Inline>
			);
			break;
		default:
			connectAction = (
				<Anchor href={url} xcss={connectLinkStyles} onClick={onConnectClick}>
					<Inline space="space.050" alignBlock="center">
						<Box xcss={connectIconStyles}>
							<LinkExternalIcon label={formatMessage(messages.externalLinkIconlabel)} />
						</Box>
						<Box>
							<FormattedMessage {...messages.connect} />
						</Box>
					</Inline>
				</Anchor>
			);
	}

	return (
		<Box xcss={tileStyles}>
			<Box xcss={contentStyles}>
				<Stack alignInline="center" space="space.025">
					<Stack space="space.100" alignInline="center">
						<Box xcss={iconStyles}>{<ProductIcon />}</Box>
						<Inline xcss={productTitleStyles} space="space.050" alignBlock="center">
							{productName}
							{recentlyConnected && (
								<Lozenge appearance="new">
									<FormattedMessage {...messages.new} />
								</Lozenge>
							)}
						</Inline>
					</Stack>
					{connectAction}
				</Stack>
			</Box>
		</Box>
	);
};
