import React, { Fragment, useCallback, useState } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';

import { type ProductKeys, ProductKeys3P, useProductConfigs } from '../../constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';
import { useBulkConnectBanner } from '../../utils/bulk-connect-banner';
import { BannerType } from '../../utils/bulk-connect-banner/types';
import { BulkConnectDialog } from '../bulk-connect-dialog';
import { OAuthMessage } from '../oauth-message';

import { getBannerContent } from './utils';

const bulkConnectBannerContainerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

type BulkConnectBannerProps = {
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	availableProducts: ProductKeys[];
	useNewlyConnectedProducts: () => ProductKeys3P[];
	isOnSearchDialog?: boolean;
	useFeatureFlaggedLocalStorageCallback: () => () => any[]; // this hook does not have strict typing
	useIsBulkConnectModalOpenQuickFind?: () => [
		boolean,
		{ setIsBulkConnectModalOpen: (value: boolean) => void },
	]; // this pertains to bulk connect modal instance on quickfind. It was necessary to prevent QF (and hence, the modal) from closing when clicking while the modal is open
};

/**
 * BulkConnectBanner component
 * This component displays a banner prompting users to connect their 3p products, and is the entrypoint to the modal dialog for connecting the products.
 */
export const BulkConnectBanner = ({
	thirdPartyConfigs,
	availableProducts,
	useNewlyConnectedProducts,
	isOnSearchDialog,
	useFeatureFlaggedLocalStorageCallback,
	useIsBulkConnectModalOpenQuickFind,
}: BulkConnectBannerProps) => {
	const allProducts = useProductConfigs();
	const newlyConnectedProducts = useNewlyConnectedProducts();

	// For QuickFind, use store state. For Full Page Search, use local state
	const [localModalOpen, setLocalModalOpen] = useState(false);
	const modalState = useIsBulkConnectModalOpenQuickFind?.() ?? [
		localModalOpen,
		{ setIsBulkConnectModalOpen: setLocalModalOpen },
	];
	const [bulkConnectModalOpen, { setIsBulkConnectModalOpen }] = modalState;

	const onCloseModal = useCallback(() => {
		// TODO: add logic to dismiss the banner, if a user has connected a product
		setIsBulkConnectModalOpen(false);
	}, [setIsBulkConnectModalOpen]);

	const { bannerType, dismissBanner } = useBulkConnectBanner({
		thirdPartyConfigs,
		availableProducts,
		newlyConnectedProducts,
		useFeatureFlaggedLocalStorageCallback,
	});

	if (bannerType === BannerType.NONE) {
		return null;
	}

	const handleAuthorize = () => {
		setIsBulkConnectModalOpen(true);
	};

	const messageContent = getBannerContent(newlyConnectedProducts, allProducts, bannerType);

	if (!messageContent) {
		return null;
	}

	const messageProps = {
		content: messageContent,
		isBulkConnect: true,
		onAuthorize: handleAuthorize,
		onDismiss: dismissBanner,
	};

	const modalContent = (
		<Fragment>
			<ModalTransition>
				{bulkConnectModalOpen && (
					<BulkConnectDialog
						availableProducts={availableProducts}
						closeModal={onCloseModal}
						thirdPartyConfigs={thirdPartyConfigs}
						newlyConnectedProducts={newlyConnectedProducts}
					/>
				)}
			</ModalTransition>
		</Fragment>
	);

	return isOnSearchDialog ? (
		<Box xcss={bulkConnectBannerContainerStyles}>
			<OAuthMessage {...messageProps} isOnSearchDialog />
			{modalContent}
		</Box>
	) : (
		<>
			<OAuthMessage {...messageProps} />
			{modalContent}
		</>
	);
};
