import { EVENT_SOURCE } from '../../constants/analytics';
import type { FireAnalyticsEvent } from '../../types';

type DegradableDependency = 'search-config';

export const onDegradedDependency = (dependency: DegradableDependency): FireAnalyticsEvent => {
	return {
		source: EVENT_SOURCE,
		action: 'degraded',
		actionSubject: 'searchPage',
		eventType: 'track',
		attributes: {
			degradedDependency: dependency,
		},
	};
};
