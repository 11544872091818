import {
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { MondayIcon } from './icon';
import { MondaySearchResultIcon } from './search-result-icon';

export const mondayConfig: ThirdPartyConfig = {
	displayName: 'Monday',
	id: 'monday',
	resourceType: 'monday-connector',
	icon: get3PLogo(MondayIcon, 'Monday', 'medium'),
	searchResultIcon: get3PLogo(MondaySearchResultIcon, 'Monday', 'large'),
	integrationAri: 'ari:cloud:platform::integration/monday',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Document],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
