import { type ThirdPartyConfig } from '../schemas/3p-config';

import { adobeSignConfig } from './adobe-sign';
import { adobeXDConfig } from './adobe-xd';
import { ahaConfig } from './aha';
import { airtableConfig } from './airtable';
import { amplitudeConfig } from './amplitude';
import { asanaConfig } from './asana';
import { atlassianWebCrawlerConfig } from './atlassian-web-crawler';
import { awss3Config } from './aws-s3';
import { azureDevopsConfig } from './azure-devops';
import { boxConfig } from './box';
import { canvaConfig } from './canva';
import { clickUpConfig } from './clickup';
import { confluenceDCConfig } from './confluence-dc';
import { docusignConfig } from './docusign';
import { dovetailConfig } from './dovetail';
import { dropboxConfig } from './dropbox';
import { figmaConfig } from './figma';
import { freshserviceConfig } from './freshservice';
import { gitbookConfig } from './gitbook';
import { githubConfig } from './github';
import { gitlabConfig } from './gitlab';
import { gmailConfig } from './gmail';
import { googleDriveConfig } from './google';
import { googleCalendarConfig } from './google-calendar';
import { hubSpotConfig } from './hubspot';
import { jiraDCConfig } from './jira-dc';
import { launchDarklyConfig } from './launchdarkly';
import { loomConfig } from './loom';
import { lucidConfig } from './lucid';
import { miroConfig } from './miro';
import { mondayConfig } from './monday';
import { muralConfig } from './mural';
import { notionConfig } from './notion';
import { oneDriveConfig } from './onedrive';
import { outlookCalendarConfig } from './outlook-calendar';
import { outlookMail } from './outlook-mail';
import { pagerDutyConfig } from './pagerduty';
import { pipedriveConfig } from './pipedrive';
import { powerBIConfig } from './powerBI';
import { salesforceConfig } from './salesforce';
import { sentryConfig } from './sentry';
import { serviceNowConfig } from './servicenow';
import { sharepointConfig } from './sharepoint';
import { slackConfig } from './slack';
import { smartsheetConfig } from './smartsheet';
import { stripeConfig } from './stripe';
import { teamsConfig } from './teams';
import { todoistConfig } from './todoist';
import { webexConfig } from './webex';
import { zendeskConfig } from './zendesk';
import { zeplinConfig } from './zeplin';
// New 3P? Add it here

export const ProductKeys3P = {
	AtlassianWebCrawler: 'atlassian-web-crawler',
	Figma: 'figma',
	Github: 'github',
	GoogleDrive: 'drive',
	Loom: 'loom',
	OneDrive: 'onedrive',
	Dropbox: 'dropbox',
	Slack: 'slack',
	Sharepoint: 'sharepoint',
	Teams: 'teams',
	GoogleCalendar: 'google-calendar',
	OutlookCalendar: 'outlook-calendar',
	OutlookMail: 'outlook-mail',
	Gmail: 'gmail',
	AdobeSign: 'adobe-sign',
	Asana: 'asana',
	Zendesk: 'zendesk',
	Airtable: 'airtable',
	Smartsheet: 'smartsheet',
	Notion: 'notion',
	Miro: 'miro',
	Docusign: 'docusign',
	Lucid: 'lucid',
	Monday: 'monday',
	AzureDevops: 'azure-devops',
	Gitlab: 'gitlab',
	ConfluenceDC: 'confluence-dc',
	Canva: 'canva',
	Box: 'box',
	Zeplin: 'zeplin',
	ServiceNow: 'servicenow',
	Freshservice: 'freshservice',
	PowerBI: 'power-bi',
	ClickUp: 'clickup',
	Amplitude: 'amplitude',
	Stripe: 'stripe',
	AdobeXD: 'adobexd',
	Aha: 'aha',
	Mural: 'mural',
	Webex: 'webex',
	Todoist: 'todoist',
	PagerDuty: 'pagerduty',
	Pipedrive: 'pipedrive',
	Hubspot: 'hubspot',
	Dovetail: 'dovetail',
	LaunchDarkly: 'launchdarkly',
	Salesforce: 'salesforce',
	Sentry: 'sentry',
	JiraDC: 'jira-dc',
	Gitbook: 'gitbook',
	AWSS3: 'aws-s3',
	// New 3P? Add it here
} as const;

export const ThirdPartyConfigs = new Map<ProductKeys3P, ThirdPartyConfig>([
	[ProductKeys3P.AtlassianWebCrawler, atlassianWebCrawlerConfig],
	[ProductKeys3P.Figma, figmaConfig],
	[ProductKeys3P.Github, githubConfig],
	[ProductKeys3P.GoogleDrive, googleDriveConfig],
	[ProductKeys3P.Sharepoint, sharepointConfig],
	[ProductKeys3P.Loom, loomConfig],
	[ProductKeys3P.OneDrive, oneDriveConfig],
	[ProductKeys3P.Dropbox, dropboxConfig],
	[ProductKeys3P.Slack, slackConfig],
	[ProductKeys3P.Teams, teamsConfig],
	[ProductKeys3P.GoogleCalendar, googleCalendarConfig],
	[ProductKeys3P.Gmail, gmailConfig],
	[ProductKeys3P.OutlookCalendar, outlookCalendarConfig],
	[ProductKeys3P.OutlookMail, outlookMail],
	[ProductKeys3P.Asana, asanaConfig],
	[ProductKeys3P.Zendesk, zendeskConfig],
	[ProductKeys3P.Airtable, airtableConfig],
	[ProductKeys3P.Smartsheet, smartsheetConfig],
	[ProductKeys3P.Notion, notionConfig],
	[ProductKeys3P.Miro, miroConfig],
	[ProductKeys3P.Docusign, docusignConfig],
	[ProductKeys3P.Lucid, lucidConfig],
	[ProductKeys3P.Monday, mondayConfig],
	[ProductKeys3P.AzureDevops, azureDevopsConfig],
	[ProductKeys3P.Gitlab, gitlabConfig],
	[ProductKeys3P.ConfluenceDC, confluenceDCConfig],
	[ProductKeys3P.Canva, canvaConfig],
	[ProductKeys3P.Box, boxConfig],
	[ProductKeys3P.Zeplin, zeplinConfig],
	[ProductKeys3P.ServiceNow, serviceNowConfig],
	[ProductKeys3P.Freshservice, freshserviceConfig],
	[ProductKeys3P.PowerBI, powerBIConfig],
	[ProductKeys3P.ClickUp, clickUpConfig],
	[ProductKeys3P.Amplitude, amplitudeConfig],
	[ProductKeys3P.Stripe, stripeConfig],
	[ProductKeys3P.AdobeXD, adobeXDConfig],
	[ProductKeys3P.Aha, ahaConfig],
	[ProductKeys3P.AdobeSign, adobeSignConfig],
	[ProductKeys3P.Mural, muralConfig],
	[ProductKeys3P.Webex, webexConfig],
	[ProductKeys3P.Todoist, todoistConfig],
	[ProductKeys3P.PagerDuty, pagerDutyConfig],
	[ProductKeys3P.Pipedrive, pipedriveConfig],
	[ProductKeys3P.Hubspot, hubSpotConfig],
	[ProductKeys3P.Dovetail, dovetailConfig],
	[ProductKeys3P.LaunchDarkly, launchDarklyConfig],
	[ProductKeys3P.Salesforce, salesforceConfig],
	[ProductKeys3P.Sentry, sentryConfig],
	[ProductKeys3P.JiraDC, jiraDCConfig],
	[ProductKeys3P.Gitbook, gitbookConfig],
	[ProductKeys3P.AWSS3, awss3Config],
	// New 3P? Add it here
]);

export type ProductKeys3P = (typeof ProductKeys3P)[keyof typeof ProductKeys3P];

const ProductsOrder3P: ProductKeys3P[] = [
	// // Loom is actually a 1P so goes to the top
	ProductKeys3P.Loom,

	// Eventually this might be "smart"
	// For now this static order isn't strongly defined
	ProductKeys3P.GoogleDrive,
	ProductKeys3P.Sharepoint,
	ProductKeys3P.OneDrive,
	ProductKeys3P.Dropbox,
	ProductKeys3P.Slack,
	ProductKeys3P.Teams,
	ProductKeys3P.Github,
	ProductKeys3P.Figma,
	ProductKeys3P.GoogleCalendar,
	ProductKeys3P.Gmail,
	ProductKeys3P.OutlookCalendar,
	ProductKeys3P.OutlookMail,
	ProductKeys3P.Airtable,
	ProductKeys3P.Smartsheet,
	ProductKeys3P.Asana,
	ProductKeys3P.Zendesk,
	ProductKeys3P.Miro,
	ProductKeys3P.Docusign,
	ProductKeys3P.Lucid,
	ProductKeys3P.Monday,
	ProductKeys3P.AzureDevops,
	ProductKeys3P.Gitlab,
	ProductKeys3P.ConfluenceDC,
	ProductKeys3P.Canva,
	ProductKeys3P.Box,
	ProductKeys3P.Zeplin,
	ProductKeys3P.ServiceNow,
	ProductKeys3P.Freshservice,
	ProductKeys3P.PowerBI,
	ProductKeys3P.ClickUp,
	ProductKeys3P.Amplitude,
	ProductKeys3P.Stripe,
	ProductKeys3P.AdobeXD,
	ProductKeys3P.Aha,
	ProductKeys3P.AdobeSign,
	ProductKeys3P.Mural,
	ProductKeys3P.Webex,
	ProductKeys3P.PagerDuty,
	ProductKeys3P.Pipedrive,
	ProductKeys3P.Dovetail,
	ProductKeys3P.LaunchDarkly,
	ProductKeys3P.Salesforce,
	ProductKeys3P.Sentry,
	ProductKeys3P.Gitbook,
	ProductKeys3P.AWSS3,
	// New 3P? Add it here

	// Always last
	ProductKeys3P.AtlassianWebCrawler,
];

/**
 * DO NOT import this directly
 *
 * Please use `import { ProductKeys3P } from 'src/common/constants/products.tsx'`
 */
export const DO_NOT_IMPORT_ProductKeys3P = ProductKeys3P;

/**
 * DO NOT import this directly
 *
 * Please use `import { ProductsOrder } from 'src/common/constants/products.tsx'`
 */
export const DO_NOT_IMPORT_ProductsOrder3P = ProductsOrder3P;
